"use client";
import Link from "next/link";
import React, { HTMLAttributes } from "react";
import styles from "./button.module.css";
import { useRouter } from "next-nprogress-bar";
interface BaseButtonProps
  extends HTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  disabled?: boolean;
  children?: React.ReactNode;
  href?: string;
  forceButton?: boolean;
  scroll?: boolean;
  prefetch?: boolean;
  target?: string;
  rel?: string;
  unstyled?: boolean;
  onClick?:
    | React.MouseEventHandler<HTMLButtonElement>
    | React.MouseEventHandler<HTMLAnchorElement>;
}
interface StandardButtonProps extends BaseButtonProps {
  variant?: StandardButtonVariant;
  color?: ButtonColor;
  size?: ButtonSize;
  mode?: ButtonColorMode;
  ghost?: boolean;
  custom?: boolean;
}
interface UniqueButtonProps extends BaseButtonProps {
  variant?: UniqueButtonVariant;
}
type StandardButtonVariant = "standard" | "circle";
type UniqueButtonVariant = "card" | "info" | "sport" | "underline" | "tile";
export type ButtonProps = StandardButtonProps | UniqueButtonProps;
type ButtonColor =
  | "primary"
  | "secondary"
  | "tertiary"
  | "primary-dark"
  | "dark"
  | "light"
  | "success"
  | "error";
type ButtonColorMode = "filled" | "bordered";
type ButtonSize = "2xs" | "xs" | "sm" | "md";
interface ButtonVariance {
  base: string;
  standard: {
    base: string;
    color: {
      [key in ButtonColorMode]: {
        [key in ButtonColor]: string;
      };
    };
    size: {
      [key in ButtonSize]: string;
    };
    ghost: string;
  };
  circle: {
    base: string;
    size: {
      [key in ButtonSize]: string;
    };
  };
  underline: string;
  card: string;
  info: string;
  sport: string;
  tile: string;
}
export const BUTTON_VARIANCE: ButtonVariance = {
  base: "flex flex-row whitespace-nowrap items-center transition-all duration-200 ease-in-out",
  standard: {
    base: "rounded-md justify-between text-center relative",
    color: {
      filled: {
        primary:
          "bg-primary-500 text-secondary-300 hover:bg-primary-600 active:bg-primary-400 decoration-primary-200",
        secondary:
          "bg-surface text-dark fill-dark hover:bg-primary active:bg-primary-400 decoration-secondary",
        "primary-dark": "bg-dark text-white fill-primary hover:opacity/75",
        tertiary:
          "bg-surface-600 text-on-tertiary fill-on-tertiary hover:bg-surface-500 active:bg-secondary-700",
        light:
          "bg-surface text-dark fill-dark hover:bg-surface-600 active:bg-surface-500",
        dark: "bg-dark text-surface fill-surface hover-bg-secondary-400 active:bg-secondary-200",
        success:
          "bg-success text-on-success hover:bg-success-600 active:bg-success-400",
        error: "bg-error text-on-error hover:bg-error-600 active:bg-error-400",
      },
      bordered: {
        primary:
          "bg-primary-500 text-secondary-300 hover:bg-primary-600 active:bg-primary-400 decoration-primary-200",
        secondary:
          "bg-surface text-dark fill-dark hover:bg-primary active:bg-primary-400 decoration-secondary",
        "primary-dark": "bg-dark text-white fill-primary hover:opacity/75",
        tertiary: "",
        light:
          "bg-transparent border-white text-white fill-white hover:bg-white hover:text-dark hover:fill-dark active:text-dark active:fill-dark border",
        dark: "bg-transparent border-dark text-dark fill-dark hover:bg-dark hover:text-white hover:fill-white active:text-white active:fill-white",
        success:
          "bg-success text-on-success hover:bg-success-600 active:bg-success-400",
        error: "bg-error text-on-error hover:bg-error-600 active:bg-error-400",
      },
    },
    size: {
      "2xs": "text-label-lg-bold px-2 uppercase h-6 gap-0.5 !rounded-[6px]",
      xs: "text-label-bold uppercase gap-1 p-3 h-8 gap-0.5",
      sm: "text-title-sm-bold uppercase px-4 py-3 h-9 gap-2",
      md: "text-title-lg-bold uppercase px-4 py-5 h-11 gap-2",
    },
    ghost: "bg-transparent border-transparent",
  },
  circle: {
    base: "justify-center rounded-full",
    size: {
      "2xs": "p-1",
      xs: "p-2",
      sm: "p-3",
      md: "p-4",
    },
  },
  underline:
    "inline-flex text-base-medium normal-case underline text-primary-200 decoration-transparent hover:decoration-primary-200",
  card: "justify-center rounded-md text-label-bold bg-transparent text-secondary uppercase py-2 px-2 gap-2 hover:bg-surface-600 outline-1",
  info: "text-label-lg-bold text-color-gray-strong uppercase hover:bg-white/40 py-1 px-1 bg-transparent rounded-md",
  sport:
    "text-title-sm-medium rounded-sm gap-2 py-1 pl-2 pr-2 outline-none bg-dark text-white fill-primary h-[26px] active:scale-95",
  tile: "h-full relative uppercase text-title-medium data-[state=active]:text-title-bold after:absolute after:-bottom-[2px] after:left-0 after:right-0 after:-right-[2px] after:bg-primary-500 after:h-[4px] after:rounded-[4px_4px_0px_0px] after:scale-y-0 after:opacity-0 after:transition-all after:origin-bottom hover:after:scale-y-100 hover:after:opacity-100 data-[state=active]:after:scale-y-100 data-[state=active]:after:opacity-100",
};

function getButtonClasses(props: ButtonProps): string {
  switch (props.variant) {
    case "card":
    case "info":
    case "underline":
    case "sport":
    case "tile":
      return `${BUTTON_VARIANCE.base} ${BUTTON_VARIANCE[props.variant]} ${
        props.className
      }`;
    default:
      const standardProps = props as StandardButtonProps;
      if (standardProps.custom) {
        return `${BUTTON_VARIANCE.base} ${
          standardProps.ghost && BUTTON_VARIANCE.standard.ghost
        } ${props.className}`;
      }
      const variant = BUTTON_VARIANCE[standardProps.variant || "standard"];
      return `${BUTTON_VARIANCE.base} ${variant.base} ${
        variant.size[standardProps.size || "md"]
      } ${
        BUTTON_VARIANCE.standard.color[standardProps.mode || "filled"][
          standardProps.color || "primary"
        ]
      } ${standardProps.ghost && BUTTON_VARIANCE.standard.ghost} ${
        props.className
      }`;
  }
}

const Button = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  ButtonProps
>((props, ref) => {
  const {
    href,
    forceButton,
    children,
    rel,
    target,
    prefetch,
    scroll,
    onClick,
    unstyled,
    ...rest
  } = props;
  const router = useRouter();

  if (href) {
    const handleClick = (e: any) => {
      e.preventDefault();
      if (props.onClick) {
        props.onClick(e);
      }
      localStorage.setItem("lastUrl", window.location.href);
      router.push(href);
    };
    if (!forceButton) {
      return (
        <Link
          {...(rest as HTMLAttributes<HTMLAnchorElement>)}
          href={href}
          rel={rel}
          target={target}
          prefetch={prefetch || true}
          scroll={scroll || true}
          className={
            !props.unstyled ? getButtonClasses(props) : props.className
          }
          onClick={handleClick}
          ref={ref as React.ForwardedRef<HTMLAnchorElement>}
        >
          {children}
        </Link>
      );
    } else {
      return (
        <button
          {...(rest as HTMLAttributes<HTMLButtonElement>)}
          className={!props.unstyled ? getButtonClasses(props) : props.className}
          onClick={handleClick}
          ref={ref as React.ForwardedRef<HTMLButtonElement>}
          disabled={props.disabled}
        >
          {children}
        </button>
      );
    }
  } else {
    return (
      <button
        {...(rest as HTMLAttributes<HTMLButtonElement>)}
        className={!props.unstyled ? getButtonClasses(props) : props.className}
        onClick={onClick as React.MouseEventHandler<HTMLButtonElement>}
        ref={ref as React.ForwardedRef<HTMLButtonElement>}
        disabled={props.disabled}
      >
        {children}
      </button>
    );
  }
});

Button.displayName = "Button";

// export default function Button(props: ButtonProps) {
//   if (props.href) {
//     return (
//       <Link
//         {...(props as HTMLAttributes<HTMLAnchorElement>)}
//         href={props.href}
//         scroll={false}
//         rel={props.rel}
//         target={props.target}
//         prefetch={props.prefetch || false}
//         className={getButtonClasses(props)}
//         onClick={props.onClick as React.MouseEventHandler<HTMLAnchorElement>}
//       >
//         {props.children}
//       </Link>
//     );
//   } else {
//     return (
//       <button
//         {...(props as HTMLAttributes<HTMLButtonElement>)}

//         className={getButtonClasses(props)}
//         onClick={props.onClick as React.MouseEventHandler<HTMLButtonElement>}
//       >
//         {props.children}
//       </button>
//     );
//   }
// }

function IconButton() {
  <></>;
}

export default Button;
